// Generated by Framer (38f2e7f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["gWIGNu5px", "rpusCEWXq", "Y2mpy1FuN"];

const serializationHash = "framer-lRIHl"

const variantClassNames = {gWIGNu5px: "framer-v-1na1tvo", rpusCEWXq: "framer-v-1ahc2y3", Y2mpy1FuN: "framer-v-11zcfnq"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Desktop Small": "rpusCEWXq", Desktop: "gWIGNu5px", Tablet: "Y2mpy1FuN"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "gWIGNu5px"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "gWIGNu5px", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={"https://framer.com/projects/new?duplicate=VdSj7EeE8qTmt2VruAW2&via=mejed_k"} openInNewTab><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1na1tvo", className, classNames)} framer-d4j4qa`} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"gWIGNu5px"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-4c5b5684-a6a8-443a-9b20-337cf2c32454, rgb(203, 179, 255))", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, boxShadow: "0px 0.636953px 1.14652px -1.125px rgba(0, 0, 0, 0.26), 0px 1.9316px 3.47689px -2.25px rgba(0, 0, 0, 0.24), 0px 5.10612px 9.19102px -3.375px rgba(0, 0, 0, 0.19), 0px 16px 28.8px -4.5px rgba(0, 0, 0, 0.03)", ...style}} {...addPropertyOverrides({rpusCEWXq: {"data-framer-name": "Desktop Small"}, Y2mpy1FuN: {"data-framer-name": "Tablet"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7UG9wcGlucy02MDA=", "--framer-font-family": "\"Poppins\", \"Poppins Placeholder\", sans-serif", "--framer-font-size": "13px", "--framer-font-weight": "600", "--framer-line-height": "1.4em", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-8edfbc8e-7da2-4ff1-8d21-4715f7f1131f, rgb(0, 0, 0)))"}}>Use for FREE</motion.p></React.Fragment>} className={"framer-fxvpzd"} fonts={["GF;Poppins-600"]} layoutDependency={layoutDependency} layoutId={"EuKBU_cBn"} style={{"--extracted-r6o4lv": "var(--token-8edfbc8e-7da2-4ff1-8d21-4715f7f1131f, rgb(0, 0, 0))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-lRIHl.framer-d4j4qa, .framer-lRIHl .framer-d4j4qa { display: block; }", ".framer-lRIHl.framer-1na1tvo { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 36px; justify-content: center; overflow: hidden; padding: 0px; position: relative; text-decoration: none; width: 142px; will-change: var(--framer-will-change-override, transform); }", ".framer-lRIHl .framer-fxvpzd { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-lRIHl.framer-1na1tvo { gap: 0px; } .framer-lRIHl.framer-1na1tvo > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-lRIHl.framer-1na1tvo > :first-child { margin-top: 0px; } .framer-lRIHl.framer-1na1tvo > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 36
 * @framerIntrinsicWidth 142
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"rpusCEWXq":{"layout":["fixed","fixed"]},"Y2mpy1FuN":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramersXp9Qzetx: React.ComponentType<Props> = withCSS(Component, css, "framer-lRIHl") as typeof Component;
export default FramersXp9Qzetx;

FramersXp9Qzetx.displayName = "Get it for FREE";

FramersXp9Qzetx.defaultProps = {height: 36, width: 142};

addPropertyControls(FramersXp9Qzetx, {variant: {options: ["gWIGNu5px", "rpusCEWXq", "Y2mpy1FuN"], optionTitles: ["Desktop", "Desktop Small", "Tablet"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramersXp9Qzetx, [{explicitInter: true, fonts: [{family: "Poppins", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLEj6V15vFP-KUEg.woff2", weight: "600"}]}], {supportsExplicitInterCodegen: true})